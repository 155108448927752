import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypePageFields = {
  title: EntryFieldTypes.Symbol;
  displayPageTitle: EntryFieldTypes.Boolean;
  slug: EntryFieldTypes.Symbol;
  parent?: EntryFieldTypes.EntryLink<TypePageSkeleton>;
  excerpt?: EntryFieldTypes.Symbol;
  content?: EntryFieldTypes.RichText;
  image?: EntryFieldTypes.AssetLink;
  metaTitle?: EntryFieldTypes.Symbol;
  metaDescription?: EntryFieldTypes.Symbol;
};

export type TypePageSkeleton = EntrySkeletonType<TypePageFields, "page">;
export type TypePage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypePageSkeleton, Modifiers, Locales>;

export function isTypePage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypePage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "page";
}
